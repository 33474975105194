import {
  UxTable,
  UxTableHeader,
  UxTableRow,
  UxTableHead,
  UxTableBody,
  UxTableDef,
  UxToggle,
  UxDot,
  UxAction,
  UxInput,
} from "syylibjs";
import useGetFlagrsQuery from "../react-query/useGetFlagrsQuery";
import { BsTrash } from "react-icons/bs";
import { AiFillPlusCircle } from "react-icons/ai";
import useUpdatePostMutation from "../react-query/useUpdateFlagrMutation";
import moment from "moment";
import useDeleteFlagrMutation from "../react-query/useDeleteFlagrMutation";
import React from "react";
import useAddFlagrMutation from "../react-query/useAddFlagrMutation";
import { useQueryClient } from "@tanstack/react-query";

type HeaderProps = {
  label: string;
  key: string;
};

const header: HeaderProps[] = [
  { label: "FLAGR", key: "key" },
  { label: "STATUS", key: "city" },
  { label: "WHEN ENABLED", key: "when_enabled" },
  { label: "LAST UPDATED", key: "last_updated" },
  { label: "", key: "" },
];

const Dashboard = () => {
  const { isLoading, isFetching, data } = useGetFlagrsQuery();
  const updateFlagr = useUpdatePostMutation();
  const deleteFlagr = useDeleteFlagrMutation();
  const addFlagr = useAddFlagrMutation();

  const [open, setOpen] = React.useState(false);
  const [openToken, setOpenToken] = React.useState(false);
  const [flagr, setFlagr] = React.useState("");
  const [token, setToken] = React.useState(localStorage.getItem("token") || "");
  const [status, setStatus] = React.useState(false);
  const [enabled, setEnabled] = React.useState(false);
  const [filterKey, setFilterKey] = React.useState("");

  const [env, setEnv] = React.useState(localStorage.getItem("env"));

  const queryClient = useQueryClient();

  return (
    <div className="flex">
      {isLoading && (
        <div className="fixed top-0 left-0 z-50 w-full h-full bg-[#00000080] flex justify-center items-center text-2xl text-light-neutral-100">
          Loading...
        </div>
      )}
      <div className="w-[20%] h-screen bg-dark-neutral-200 border-r border-r-mid-neutral-300 px-5">
        <div className="my-8 text-light-neutral-300 text-3xl font-bold text-left line-clamp-1 text-ellipsis">
          SyyFlagr
        </div>
        <div className="h-[calc(100%-7rem)] flex flex-col justify-between">
          <div>
            <div
              className={`w-full py-2 px-4 font-bold mb-3 rounded-lg ${
                env === "beta"
                  ? "bg-primary text-light-neutral-100"
                  : "bg-dark-neutral-300 text-mid-neutral-100"
              } ${
                data && data.type === "error"
                  ? "pointer-events-none cursor-none opacity-50"
                  : "pointer-events-auto cursor-pointer"
              }
          `}
              onClick={() => {
                localStorage.setItem("env", "beta");
                setEnv("beta");
                queryClient.invalidateQueries({ queryKey: ["getFlagrs"] });
                setFilterKey("");
              }}
            >
              BETA
            </div>
            <div
              className={`w-full py-2 px-4 font-bold cursor-pointer mb-3 rounded-lg ${
                env === "live"
                  ? "bg-primary text-light-neutral-100"
                  : "bg-dark-neutral-300 text-mid-neutral-100"
              } ${
                data && data.type === "error"
                  ? "pointer-events-none cursor-none opacity-50"
                  : "pointer-events-auto cursor-pointer"
              }`}
              onClick={() => {
                localStorage.setItem("env", "live");
                setEnv("live");
                queryClient.invalidateQueries({
                  queryKey: ["getFlagrs"],
                });
                setFilterKey("");
              }}
            >
              LIVE
            </div>
          </div>
          <div
            className={`w-full py-2 px-4 font-bold mb-3 rounded-lg bg-mid-neutral-300 text-light-neutral-200 cursor-pointer`}
            onClick={() => {
              setOpenToken(!openToken);
            }}
          >
            AUTH
          </div>
        </div>
      </div>
      <div className="w-[80%] py-8 px-8">
        <div className="flex justify-between">
          {data && data.type !== "error" && data.data.data.length && (
            <UxInput
              type="text"
              value={filterKey}
              placeholder="Filter by key..."
              onType={(e) => {
                setFilterKey(e.target.value.toLowerCase());
              }}
              cxLayout="w-fit bg-white border h-5 py-5 px-2"
            />
          )}
          <UxAction
            type="button"
            onAction={() => {
              setOpen(!open);
            }}
            rounded
            cx={isFetching ? "pointer-events-none opacity-50" : ""}
            leftIcon={AiFillPlusCircle}
            clickable={data && data.type !== "error"}
          >
            Add Flagr
          </UxAction>
        </div>
        {data &&
        data.type === "success" &&
        data.data.data &&
        data.data.data.length ? (
          <UxTable cx="w-full mt-5 mb-10">
            <UxTableHeader>
              <UxTableRow cx="text-left">
                {header.map((head, i) => {
                  return (
                    <UxTableHead key={i}>
                      <div className="mr-4">{head.label}</div>
                    </UxTableHead>
                  );
                })}
              </UxTableRow>
            </UxTableHeader>
            <UxTableBody>
              {(data.data.data as any[])
                .sort((f, s) =>
                  f.key.toLowerCase() < s.key.toLowerCase() ? -1 : 1
                )
                .filter((d) => d.key.toLowerCase().includes(filterKey))
                .map((row, i) => {
                  console.log(data);
                  return (
                    <UxTableRow key={i}>
                      <UxTableDef cx="line-clamp-1 border-none">
                        {row.key}
                      </UxTableDef>
                      <UxTableDef>
                        <div
                          className={`
                        flex items-center
                        ${isFetching ? "pointer-events-none opacity-50" : ""}
                      `}
                        >
                          F
                          <div className="mx-3">
                            <UxToggle
                              on={row.status}
                              onAction={() => {
                                updateFlagr.mutate({
                                  key: row.key,
                                  status: !row.status,
                                  when_enabled: row.when_enabled,
                                });
                              }}
                            />
                          </div>
                          T
                        </div>
                      </UxTableDef>
                      <UxTableDef>
                        <div className="flex items-center">
                          <UxDot
                            variant={row.when_enabled ? "success" : "error"}
                            size="md"
                            cx="mr-2"
                          />
                          {row.when_enabled ? "ON" : "OFF"}
                        </div>
                      </UxTableDef>
                      <UxTableDef>
                        <p className="break-all line-clamp-1">
                          {moment(
                            new Date(row.last_updated).toISOString()
                          ).fromNow()}
                        </p>
                      </UxTableDef>
                      <UxTableDef cx="flex justify-center border-none">
                        <UxAction
                          type="icon"
                          onAction={() => {
                            deleteFlagr.mutate(row.key);
                          }}
                          rounded
                          cx={
                            isFetching ? "pointer-events-none opacity-50" : ""
                          }
                        >
                          <BsTrash />
                        </UxAction>
                      </UxTableDef>
                    </UxTableRow>
                  );
                })}
            </UxTableBody>
          </UxTable>
        ) : data && data.type === "error" ? (
          <div className="w-full h-20 flex justify-center items-center bg-dark-neutral-100 text-xl text-light-neutral-200 font-bold mt-5 rounded">
            Invalid token!
          </div>
        ) : (
          <div className="w-full h-20 flex justify-center items-center bg-dark-neutral-100 text-xl text-light-neutral-200 font-bold mt-5 rounded">
            Click on Add Flagr
          </div>
        )}
        {open && (
          <div className="fixed top-0 left-0 z-50 w-full h-full bg-[#00000080] flex flex-col items-center text-2xl text-light-neutral-100">
            <div className="w-full h-fit max-w-[30rem] bg-light-neutral-200 text-dark-neutral-300 mt-16 rounded-xl py-5 px-10">
              <div className="mt-5 mb-8 text-2xl font-bold">Add Flagr</div>
              <div className="text-lg font-normal mb-2">Flagr</div>
              <UxInput
                type="text"
                value={flagr}
                placeholder="Enter Flagr Key..."
                onType={(e) => {
                  setFlagr(e.target.value);
                }}
                rounded={false}
                cxLayout="w-full bg-white border h-5 py-6 px-2"
              />
              <div className="text-lg font-normal mt-6 mb-2">Status</div>
              <div className="text-xs flex items-center">
                F
                <div className="mx-3">
                  <UxToggle
                    on={status}
                    onAction={() => {
                      setStatus(!status);
                    }}
                  />
                </div>
                T
              </div>
              <div className="text-lg font-normal mt-6 mb-2">When Enabled</div>
              <div className="text-xs flex items-center">
                F
                <div className="mx-3">
                  <UxToggle
                    on={enabled}
                    onAction={() => {
                      setEnabled(!enabled);
                    }}
                  />
                </div>
                T
              </div>
              <div className="flex justify-end mt-8 mb-5">
                <UxAction
                  type="button"
                  variant="transparent"
                  onAction={() => {
                    setFlagr("");
                    setStatus(false);
                    setEnabled(false);
                    setOpen(false);
                  }}
                  size="lg"
                  cx="text-dark-neutral-300 mr-3"
                >
                  Cancel
                </UxAction>
                <UxAction
                  type="button"
                  onAction={() => {
                    {
                      if (flagr.trim() !== "") {
                        addFlagr.mutate({
                          key: flagr,
                          status: status,
                          when_enabled: enabled,
                        });
                        setFlagr("");
                        setStatus(false);
                        setEnabled(false);
                        setOpen(false);
                      }
                    }
                  }}
                  size="lg"
                >
                  Add Flagr
                </UxAction>
              </div>
            </div>
          </div>
        )}
        {openToken && (
          <div className="fixed top-0 left-0 z-50 w-full h-full bg-[#00000080] flex flex-col items-center text-2xl text-light-neutral-100">
            <div className="w-full h-fit max-w-[30rem] bg-light-neutral-200 text-dark-neutral-300 mt-16 rounded-xl py-5 px-10">
              <div className="mt-5 mb-5 text-2xl font-bold">Add Token</div>
              <UxInput
                type="text"
                value={token}
                placeholder="Enter the token..."
                onType={(e) => {
                  setToken(e.target.value);
                }}
                rounded={false}
                cxLayout="w-full bg-white border h-5 py-6 px-2"
              />
              <div className="flex justify-end mt-8 mb-5">
                <UxAction
                  type="button"
                  variant="transparent"
                  onAction={() => {
                    setOpenToken(false);
                  }}
                  size="lg"
                  cx="text-dark-neutral-300 mr-3"
                >
                  Cancel
                </UxAction>
                <UxAction
                  type="button"
                  onAction={() => {
                    {
                      if (token.trim() !== "") {
                        localStorage.setItem("token", token);
                        setOpenToken(false);
                        queryClient.invalidateQueries({
                          queryKey: ["getFlagrs"],
                        });
                      }
                    }
                  }}
                  size="lg"
                >
                  Add Token
                </UxAction>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
