export type FlagrType = {
  key: string;
  status: boolean;
  when_enabled: boolean;
  last_updated: any;
};

export const rectifyFormat = (s: string) => {
  let b = s.split(/\D/);
  return (
    b[0] +
    "-" +
    b[1] +
    "-" +
    b[2] +
    "T" +
    b[3] +
    ":" +
    b[4] +
    ":" +
    b[5] +
    "." +
    b[6].substring(0, 3) +
    "+00:00"
  );
};

const updateFlagr = async (updateSet: Omit<FlagrType, "last_updated">) => {
  const table = localStorage.getItem("env")!;

  const updateDataSet = {
    key: updateSet.key,
    status: updateSet.status,
    when_enabled: updateSet.when_enabled,
    last_updated: rectifyFormat(new Date().toISOString()),
  };

  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/${table}Flagger/updateFlag`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ ...updateDataSet }),
    }
  );

  if (res.status === 200) {
    return {
      type: "success",
      data: "Flagr updated",
    };
  } else {
    console.log(res.status);
    return {
      type: "error",
      data: "Failed to update flagr",
    };
  }
};

export default updateFlagr;
