import { useMutation, useQueryClient } from "@tanstack/react-query";
import deleteFlagr from "../api/deleteFlagr";

const useDeleteFlagrMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (key: string) => deleteFlagr(key),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["getFlagrs"] }),
  });
};

export default useDeleteFlagrMutation;
