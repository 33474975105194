import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FlagrType } from "../api/updateFlagr";
import addFlagr from "../api/addFlagr";

const useAddFlagrMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updateSet: Omit<FlagrType, "last_updated">) =>
      addFlagr(updateSet),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["getFlagrs"] }),
  });
};

export default useAddFlagrMutation;
