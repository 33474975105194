const deleteFlagr = async (key: string) => {
  const table = localStorage.getItem("env")!;

  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/${table}Flagger/deleteFlag/${key}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  if (res.status === 200) {
    return {
      type: "success",
      data: "Flagr deleted",
    };
  } else {
    return {
      type: "error",
      data: "Failed to delete flagr",
    };
  }
};

export default deleteFlagr;
