import { useMutation, useQueryClient } from "@tanstack/react-query";
import updateFlagr, { FlagrType } from "../api/updateFlagr";

const useUpdatePostMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updateSet: Omit<FlagrType, "last_updated">) =>
      updateFlagr(updateSet),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["getFlagrs"] }),
    retry: false,
  });
};

export default useUpdatePostMutation;
