import { FlagrType, rectifyFormat } from "./updateFlagr";

const addFlagr = async (addSet: Omit<FlagrType, "last_updated">) => {
  const table = localStorage.getItem("env")!;
  const getData = {
    ...addSet,
    last_updated: rectifyFormat(rectifyFormat(new Date().toISOString())),
  };

  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/${table}Flagger/createFlag`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(getData),
    }
  );

  if (res.status === 200) {
    return {
      type: "success",
      data: "Flagr added",
    };
  } else {
    console.log(res.status);
    return {
      type: "error",
      data: "Failed to add flagr",
    };
  }
};

export default addFlagr;
