import { useQuery } from "@tanstack/react-query";
import getFlagrs from "../api/getFlagrs";

const useGetFlagrsQuery = () => {
  return useQuery(
    ["getFlagrs"],
    async () => {
      const flagrs = await getFlagrs();
      return flagrs;
    },
    { refetchOnWindowFocus: false }
  );
};

export default useGetFlagrsQuery;
